import React, { Component } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { Wrapper, Label, Item, ErrorMessage } from './styled'

class FormItem extends Component {
  state = {
    activeLabel: false,
  }

  handleInputFocus = () => {
    this.setState({
      activeLabel: true,
    })
  }

  handleInputBlur = ({ value, onBlur }, event) => {
    if (!value) {
      this.setState({
        activeLabel: false,
      })
    }

    onBlur(event)
  }

  render() {
    const {
      field,
      form: { touched, errors },
      placeholder,
      ...props
    } = this.props

    let Component = 'input'

    if (props && props.componenttype === 'textarea') {
      Component = TextareaAutosize  
    }

    return (
      <Item>
        <Wrapper>
          <Label active={this.state.activeLabel}>{placeholder}</Label>
          <Component
            {...field}
            {...props}
            onFocus={() => this.handleInputFocus()}
            onBlur={event => this.handleInputBlur(field, event)}
          />
        </Wrapper>

        {touched[field.name] &&
          errors[field.name] && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
      </Item>
    )
  }
}

export default FormItem
