import React from 'react'

import Navbar from 'components/shared/Navbar'
import Footer from 'components/shared/Footer'
import Layout from 'components/shared/Layout'
import PageTitle from 'components/shared/PageTitle'

import Content from 'components/Contact/Content'

const Contact = () => (
  <Layout>
    <Navbar />
    <PageTitle title="Contact us" subtitle="Let's talk." />
    <Content />
    <Footer />
  </Layout>
)

export default Contact
