import styled from 'styled-components'
import { media } from 'themes'

export const Title = styled.h3`
  color: #caaa9a;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 16px;
`

export const Details = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 48px;

  ${media.md`
    margin-bottom: 40px;
  `}
`

export const Map = styled.iframe`
  display: block;
  width: 100%;
  height: 224px;
  border: 0;
  margin-top: 80px;
`