import React from 'react'
import { Container, Row, Col } from 'styled-bootstrap-grid'
import Form from '../Form'
import { Title, Details, Map } from './styled'

const Content = () => (
  <>
    <Container>
      <Row>
        <Col md={6} lg={8}>
          <Row>
            <Col lg={6}>
              <Title>Call or write to us:</Title>
              <Details>
                (646) 503-5341
                <br />
                rsadowski@robertwsadowski.com
              </Details>
            </Col>
            <Col lg={6}>
              <Title>Visit us:</Title>
              <Details>
                757 Third Avenue, 20th floor,
                <br />
                New York, NY 10017
              </Details>
            </Col>
          </Row>
        </Col>
        <Col md={6} lg={4}>
          <Title>Send us message:</Title>

          <Form />
        </Col>
      </Row>
    </Container>

    <Map
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.3686645822154!2d-73.9738506841094!3d40.75391554298617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258fd5237d0b9%3A0x2e60e14cb0e99c45!2s757%203rd%20Ave%2C%20New%20York%2C%20NY%2010017%2C%20Stany%20Zjednoczone!5e0!3m2!1spl!2spl!4v1642235615952!5m2!1spl!2spl"
      height="224"
      frameborder="0"
      allowfullscreen
    />
  </>
)

export default Content
