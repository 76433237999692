import styled from 'styled-components'
import { media } from 'themes'

export const SubmitButton = styled.button`
  color: #C74513;
  font-family: Futura;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  float: right;
  background: transparent;
  border: 0;
  padding: 0;

  &:hover {
    cursor: pointer;
  }

  ${({ disabled }) => disabled && `
    color: #DDD;
  `}

  ${media.sm`
    float: none;
  `}
`
