import styled from 'styled-components'

export const Item = styled.div`
  margin-bottom: 32px;
`

export const Wrapper = styled.div`
  position: relative;

  input,
  textarea {
    display: inline-block;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #d9d9d9;
    background: transparent;
    font-family: 'Futura', sans-serif;
    font-size: 14px;
    line-height: 26px;
    padding-bottom: 8px;

    &:focus {
      outline: 0;
    }
  }

  textarea {
    resize: none;
    min-height: 100px;
  }
`

export const Label = styled.label`
  position: absolute;
  top: 3px;
  left: 0;
  pointer-events: none;
  transition: top 150ms, font-size 200ms;
  color: #9d9d9d;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;

  ${({ active }) =>
    active &&
    `
    top: -22px;
    font-size: 12px;
  `};
`

export const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`
